<template>
  <div>
    <!-- Totalizers -->
    <backoffice-totalizer 
      :description="totalizerDescription"
      :totalizer="totalizer"
    />
    <!-- Filters -->
    <b-card no-body>
      <b-table
        hover
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="user_id"
        show-empty
        empty-text="Não foram encontradas NPS para esta busca"
        :busy="loading.table"
        @row-clicked="rowClicked"
      >
        <!-- Column: Franquia -->
        <template #cell(franchise_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.franchise_name }}</span>
          </div>
        </template>
         <!-- Column: QTD Enviadas -->
         <template #cell(sent_count)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.sent_count }}</span>
          </div>
        </template>
        <!-- Column: PN -->
        <template #cell(consultant_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.consultant_name }}</span>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import BackofficeTotalizer from '../components/BackofficeTotalizer.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    BackofficeTotalizer
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: {
        table: false
      },
    };
  },
  watch: {
    franchise(){
      this.search()
    }
  },
  computed: {
    ...mapGetters({
      npsSearchResult: types.NPS_FRANCHISES_CONSULTANTS_LIST,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      totalizer: types.NPS_TOTALIZER
    }),
    franchise: function(){
      if(this.$route.params.franchise){
        return [this.$route.params.franchise]
      }
      return []
    },
    isFranchises: function(){
      return this.franchise.length > 0
    },
    totalizerDescription: function(){
      if(this.npsSearchResult?.length > 0){
        if(this.isFranchises){
          return this.npsSearchResult[0].franchise_name
        }
        return ''
      }
    },
    tableColumns: function() {
      const columns = []
      if (this.isFranchises) {
        columns[0] = { key: "consultant_name", label: "PN", sortable: true }
      }else{
        columns[0] = { key: "franchise_name", label: "Franquia", sortable: true }
      }
      columns[1] = { key: "sent_count", label: "Qtde Enviadas", sortable: true}
      const columnSize = columns.length - 1
      if(this.npsSearchResult?.length > 0){
        const maxAnswerCounter = _.maxBy(this.npsSearchResult, 'survey_answer_counter').survey_answer_counter
        for(var i = 1; i <= maxAnswerCounter; i++){
          if(i == 1){
            columns[i + columnSize] = { key: `answer_${i}`, label: `${i} mês`, sortable: true }
          }else{
            columns[i + columnSize] = { key: `answer_${i}`, label: ` ${ (i-1) * 6} meses`, sortable: true }
          }
        }
      }
      return columns
    },
    items: function () {
      if(this.npsSearchResult?.length > 0){
        var groupBy = 'franchise_name'
        if(this.isFranchises){
          groupBy = 'consultant_name'
        }
        var items = _(this.npsSearchResult)
          .groupBy(groupBy)
          .map(objs => ({
            consultant_name: objs[0].consultant_name,
            franchise_name: objs[0].franchise_name,
            digital_franchise_id: objs[0].digital_franchise_id,
            consultant_id : objs[0].consultant_id,
            sent_count: objs[0].sent_count,
            ...objs.reduce((acc, curr) => {
              const counter = `answer_${curr.survey_answer_counter}`;
              acc[counter] = curr.nps_average;
              return acc;
            }, {})
          }))
          .orderBy(groupBy)
          .value();
        return items 
      }
      return [];
    }
  },
  mounted() {
    this.search();
  },
  methods: {
    ...mapActions({
      searchNps: types.GET_NPS_FRANCHISES_CONSULTANTS_LIST,
      getTotalizer: types.GET_NPS_TOTALIZER
    }),
    search() {
      this.loading.table = true;
      this.searchNps({
        franchise: this.franchise
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os NPS. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.table = false;
        });
      this.getTotalizer({
        franchise: this.franchise
      }).catch((error) => {
        this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao recuperar os dados do totalizador. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    rowClicked(row){
      if(!this.isFranchises){
        this.$router.push({
          name: 'nps-franchise-details', 
          params: { 
            franchise: row.digital_franchise_id 
          }
        })
      }else{
        this.$router.push({ 
          name: 'nps-consultant-details', 
          params: { 
            consultant: row.consultant_id 
          } 
        })
      }
    },
    
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
